import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Note from '../../model/Note';
import NotesService from '../../service/notes-service';
import EditableNote from './editable-note';

export interface IEditNoteModalProps {
  open: boolean;
  onClose: Function;
  note: Note;
  excludeDates: Date[];
}

export default function EditNoteModal(props: IEditNoteModalProps) {
  return (
    <Modal dimmer={props.open ? 'blurring' : undefined}
      open={props.open}
      onClose={() => props.onClose()}
      style={{ top: '8em', maxWidth: '40em' }}
      basic >
      <EditableNote
        note={props.note}
        excludeDates={props.excludeDates}
        notesService={new NotesService()} />
    </Modal>
  );
}
