import firebase from "../firebase";
import "firebase/auth";
import "firebase/firestore";
import Note from '../model/Note';

export interface NoteChangeEvent {
  type: string;
  note: Note;
}

export interface INotesService {
  listen(userId: string, notebookId: string, onChange: any): Function;
  create(userId: string, note: Note): Promise<Note>;
  update<Void>(userId: string, note: Note): Promise<void>;
  delete<Void>(userId: string, note: Note): Promise<void>;
}

export default class NotesService implements INotesService {
  private db: firebase.firestore.Firestore = firebase.firestore();

  public listen(userId: string, notebookId: string, onChange: any): Function {
    return this.db.collection('users')
      .doc(userId)
      .collection('notebooks')
      .doc(notebookId)
      .collection('notes')
      .onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
        const changes: NoteChangeEvent[] = [];
        for (const change of querySnapshot.docChanges()) {
          console.log('procesing note change');
          changes.push({
            type: change.type,
            note: Note.from(change.doc, notebookId)
          } as NoteChangeEvent);
        }

        if (querySnapshot.size + querySnapshot.docChanges.length === 0) {
          changes.push({
            type: 'first_empty',
            note: null
          } as NoteChangeEvent);
        }

        onChange(changes);
      });
  }

  public async create(userId: string, note: Note): Promise<Note> {
    const noteRef: firebase.firestore.DocumentReference = await this.db.collection('users')
      .doc(userId)
      .collection('notebooks')
      .doc(note.notebookId)
      .collection('notes')
      .add(note.toMap());
    return Note.from(await noteRef.get(), note.notebookId);
  }

  public async update<Void>(userId: string, note: Note) {
    await this.db.collection('users')
      .doc(userId)
      .collection('notebooks')
      .doc(note.notebookId)
      .collection('notes')
      .doc(note.id)
      .set(note.toMap());
  }

  public async delete<Void>(userId: string, note: Note) {
    await this.db.collection('users')
      .doc(userId)
      .collection('notebooks')
      .doc(note.notebookId)
      .collection('notes')
      .doc(note.id)
      .delete();
  }
}