import Note from "../model/Note";
import { NoteChangeEvent } from "./notes-service";

export default class NotesUtil {
  public static merge(change: NoteChangeEvent, notes: Note[]): Note[] {
    if (change.type === 'removed') {
      return this.remove(notes, change.note);
    } else if (change.type === 'added' || change.type === 'modified') {
      const merged: Note[] = this.remove(notes, change.note);
      merged.push(change.note); 
      return merged;
    } else if (change.type === 'first_empty') {
      return [];
    }
    
    console.log('unknown change event ' + change.type);
    return notes;
  }

  private static remove(notes: Note[], toRemove: Note): Note[] {
    return notes.filter(n => n.id !== toRemove.id);
  }
}