import React from 'react';
import { Dropdown, Menu } from "semantic-ui-react";
import { AppContext } from '../../context/AppContext';
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
import { NotebooksMenu as NotebooksMenuItem } from './notebooks-menu';

export default function Header() {
  const { user } = React.useContext(AppContext);
  const history = useHistory();

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(res => {
        history.push("/login");
      })
  }

  return (
    <Menu fixed='top' size='huge'>
      <Menu.Item
        icon='sticky note'
        name='daily notes'
      />
      { user && <NotebooksMenuItem /> }
      <Menu.Menu position='right'>
        <Dropdown item icon='setting' disabled={!user}>
          <Dropdown.Menu>
            <Dropdown.Item >{user?.displayName}</Dropdown.Item>
            <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}
