import React from 'react';
import { Container } from "semantic-ui-react";

interface IMainContentProps {
  children: any
}

export default function MainContent(props: IMainContentProps) {

  return (
    <Container style={{marginTop: '5em'}}>
      {props.children}
    </Container>
  );
}
