import * as React from "react";
import { Fragment, useEffect } from "react";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { AppContext } from "../../context/AppContext";
import Notebook from "../../model/Notebook";
import NotebooksService, {
  NotebookChangeEvent,
} from "../../service/notebooks-service";
import NotebooksUtil from "../../service/notebooks-util";
import { CreateNotebookModal } from "./create-notebook-modal";
import DeleteNotebookConfirmModal from "./delete-notebook-confirm-modal";
import RenameNotebookModal from "./rename-notebook-modal";

interface NotebooksMenuState {
  allNotebooks: Notebook[];
  showAddNotebookModal: boolean;
  showRenameNotebookModal: boolean;
  showDeleteNotebookConfirmation: boolean;
  loadingNotebooks: boolean;
}

const initialState: NotebooksMenuState = {
  allNotebooks: null,
  showAddNotebookModal: false,
  showRenameNotebookModal: false,
  showDeleteNotebookConfirmation: false,
  loadingNotebooks: true,
};

type NotebooksMenuAction =
  | {
      type: "setNotebooks";
      changes: NotebookChangeEvent[];
    }
  | {
      type: "setShowAddNotebookModal";
      value: boolean;
    }
  | {
      type: "setShowRenameNotebookModal";
      value: boolean;
    }
  | {
      type: "setShowDeleteNotebookModal";
      value: boolean;
    }
  | {
      type: "setLoadingNotebooks";
      value: boolean;
    };

function reducer(
  state: NotebooksMenuState,
  action: NotebooksMenuAction
): NotebooksMenuState {
  if (action.type === "setNotebooks") {
    let updatedNotebooks: Notebook[] = state.allNotebooks || [];
    for (const change of action.changes) {
      updatedNotebooks = NotebooksUtil.merge(change, updatedNotebooks);
    }
    return {
      ...state,
      allNotebooks: updatedNotebooks,
      loadingNotebooks: false,
    };
  } else if (action.type === "setLoadingNotebooks") {
    return {
      ...state,
      loadingNotebooks: action.value,
    };
  } else if (action.type === "setShowAddNotebookModal") {
    return {
      ...state,
      showAddNotebookModal: action.value,
    };
  } else if (action.type === "setShowRenameNotebookModal") {
    return {
      ...state,
      showRenameNotebookModal: action.value,
    };
  } else if (action.type === "setShowDeleteNotebookModal") {
    return {
      ...state,
      showDeleteNotebookConfirmation: action.value,
    };
  }
}

export function NotebooksMenu() {
  const { user, currentNotebook, selectNotebook } =
    React.useContext(AppContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    if (!user) return;
    const unsubcribe: any = new NotebooksService().listen(
      user.uid,
      (changes: NotebookChangeEvent[]) => {
        dispatch({ type: "setLoadingNotebooks", value: true });
        dispatch({ type: "setNotebooks", changes: changes });
      }
    );
    return unsubcribe;
  }, [user]);

  return (
    <Fragment>
      <Menu.Menu position="left">
        <Dropdown
          item
          disabled={!currentNotebook}
          text={
            "Notebook: " + (state.loadingNotebooks ? "" : currentNotebook?.name)
          }
          loading={state.loadingNotebooks}
        >
          <Dropdown.Menu>
            {state.allNotebooks &&
              state.allNotebooks
                .sort((a, b) => a.order - b.order)
                .map((nb) => {
                  return (
                    <Dropdown.Item
                      key={nb.id}
                      selected={
                        state.allNotebooks.length > 1 &&
                        nb.id === currentNotebook?.id
                      }
                      text={nb.name}
                      onClick={() => selectNotebook(nb)}
                    />
                  );
                })}
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() =>
                dispatch({ type: "setShowRenameNotebookModal", value: true })
              }
            >
              <Icon name="edit" />
              Rename
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                dispatch({ type: "setShowDeleteNotebookModal", value: true })
              }
              disabled={state.allNotebooks?.length <= 1}
            >
              <Icon name="trash" style={{ color: "#c30000" }} />
              <span style={{ color: "#c30000" }}>Delete</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() =>
                dispatch({ type: "setShowAddNotebookModal", value: true })
              }
            >
              <Icon name="add" />
              New
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
      <CreateNotebookModal
        open={state.showAddNotebookModal}
        close={() =>
          dispatch({ type: "setShowAddNotebookModal", value: false })
        }
        allNotebooks={state.allNotebooks}
      />
      <RenameNotebookModal
        open={state.showRenameNotebookModal}
        close={() =>
          dispatch({ type: "setShowRenameNotebookModal", value: false })
        }
        notebook={currentNotebook}
      />
      <DeleteNotebookConfirmModal
        open={state.showDeleteNotebookConfirmation}
        close={() =>
          dispatch({ type: "setShowDeleteNotebookModal", value: false })
        }
        notebook={currentNotebook}
        allNotebooks={state.allNotebooks}
      />
    </Fragment>
  );
}
