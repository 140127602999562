import { DateTime } from "luxon";

export default class Note {
  id: string;
  date: Date;
  textHtml: string;
  notebookId: string;

  static ianaTimeZone: string = 'Europe/London';

  constructor(date: Date, notebookId: string) {
    date.setHours(0, 0, 0, 0);
    this.date = date;
    this.notebookId = notebookId;
    this.textHtml = '';
  }

  public getDateFormatted(): string {
    let dateFullFormatted = DateTime.fromJSDate(this.date)
      .setZone(Note.ianaTimeZone)
      .toLocaleString({
        locale: 'en-GB',
        year: "numeric",
        month: "long",
        day: "2-digit"
      });

    if (this.isToday()) {
      return 'Today';
    }

    return dateFullFormatted;
  }

  public isToday() {
    const now: DateTime = DateTime.fromObject({ zone: Note.ianaTimeZone });
    const zoned: DateTime = DateTime.fromJSDate(this.date).setZone(Note.ianaTimeZone); 
    return zoned.year === now.year
      && zoned.month === now.month
      && zoned.day === now.day;
  }

  public toMap() {
    return {
      date: this.date,
      textHtml: this.textHtml
    }
  }

  public static from(documentSnapshot: firebase.firestore.DocumentSnapshot, notebookId: string): Note {
    const note: Note = new Note(documentSnapshot.data()['date'].toDate(), notebookId);
    note.id = documentSnapshot.id;
    note.textHtml = documentSnapshot.data()['textHtml'];
    return note;
  }
}