import firebase from "../firebase";
import "firebase/auth";
import "firebase/firestore";
import User from "../model/User";

export default class UserService {
  private db: firebase.firestore.Firestore = firebase.firestore();

  public async create(userId: string, displayName: string): Promise<User> {
    const user: User = new User(displayName, userId);
    await this.db.collection("users").doc(userId).set(user.toMap());
    return user;
  }

  public async retrieve(userId: string): Promise<User> {
    const userRef: firebase.firestore.DocumentReference = await this.db
      .collection("users")
      .doc(userId);
    const userDoc = await userRef.get();

    return User.fromSnapshot(userDoc);
  }

  public async createIfNotExists(
    userId: string,
    displayName: string
  ): Promise<User> {
    let user = await this.retrieve(userId);
    if (user == null) {
      user = await this.create(userId, displayName);
    }

    return user;
  }

  public listen(userId: string, onChange: any): Function {
    return this.db
      .collection("users")
      .doc(userId)
      .onSnapshot((user: firebase.firestore.DocumentSnapshot) => {
        onChange(User.fromSnapshot(user));
      });
  }

  public async setLastSelectedNotebook(
    userId: string,
    notebookId: string
  ): Promise<void> {
    await this.db
      .collection("users")
      .doc(userId)
      .update({ lastSelectedNotebookId: notebookId });
  }
}
