import * as React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import NoteModel from '../../model/Note';
import './note.css';

export interface INoteProps {
  note: NoteModel;
  onClick: Function;
  delete?: Function;
  style?: any;
}

export default function Note(props: INoteProps) {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  const handleDelete = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    props.delete();
  }

  return (
    <Card className={'note' + (props.note.isToday() ? ' today' : '')} centered onClick={() => props.onClick(props.note)} as='div' raised={false} >
        <Card.Content onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
          <Card.Header>
            {props.note.getDateFormatted()}
            <div className='delete-note' style={{ opacity: showOptions && props.delete ? 'inherit' : '0%' }}> <Icon color='grey' name='trash' size='small' onClick={handleDelete} /> </div>
          </Card.Header>
          <Card.Description dangerouslySetInnerHTML={{ __html: props.note.textHtml }} />
        </Card.Content>
      </Card>
  );
}
