import Notebook from "../model/Notebook";
import { NotebookChangeEvent } from "./notebooks-service";

export default class NotebooksUtil {
  public static merge(change: NotebookChangeEvent, notes: Notebook[]): Notebook[] {
    if (change.type === 'removed') {
      return this.remove(notes, change.notebook);
    } else if (change.type === 'added' || change.type === 'modified') {
      const merged: Notebook[] = this.remove(notes, change.notebook);
      merged.push(change.notebook);
      return merged;
    } else if (change.type === 'first_empty') {
      return [];
    }
    
    console.log('unknown change event ' + change.type);
    return notes;
  }

  private static remove(notes: Notebook[], toRemove: Notebook): Notebook[] {
    return notes.filter(n => n.id !== toRemove.id);
  }
}