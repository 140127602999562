import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "./context/AppContext";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const {user, currentNotebook } = useContext(AppContext);

    const render = (routeProps) => {
        if (!user || !currentNotebook) {
            return <Redirect to={ "/login" } />
        } 

        return <RouteComponent {...routeProps} />;
    }

    return (
        <Route render={render} />
    );
}

export default PrivateRoute