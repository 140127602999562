import firebase from "../firebase";
import "firebase/auth";
import "firebase/firestore";
import Notebook from "../model/Notebook";
import User from "../model/User";

export interface NotebookChangeEvent {
  type: string;
  notebook: Notebook;
}

export default class NotebooksService {
  private db: firebase.firestore.Firestore = firebase.firestore();

  public listen(userId: string, onChange: any): Function {
    return this.db
      .collection("users")
      .doc(userId)
      .collection("notebooks")
      .onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
        const changes: NotebookChangeEvent[] = [];
        for (const change of querySnapshot.docChanges()) {
          console.log("procesing notebook change");
          changes.push({
            type: change.type,
            notebook: Notebook.from(change.doc),
          } as NotebookChangeEvent);
        }

        if (querySnapshot.size + querySnapshot.docChanges.length === 0) {
          changes.push({
            type: "first_empty",
            notebook: null,
          } as NotebookChangeEvent);
        }

        onChange(changes);
      });
  }

  public async retrieveOrCreateDefaultNotebook(user: User): Promise<Notebook> {
    const notebooksRef: firebase.firestore.CollectionReference = await this.db
      .collection("users")
      .doc(user.uid)
      .collection("notebooks");

    if ((await notebooksRef.get()).empty) {
      return this.create(user.uid, Notebook.createDefault());
    }

    let defaultNotebook;
    if (user.lastSelectedNotebookId) {
      const documentSnapshot: firebase.firestore.DocumentSnapshot =
        await notebooksRef.doc(user.lastSelectedNotebookId).get();
      defaultNotebook = Notebook.from(documentSnapshot);
    }

    if (!defaultNotebook) {
      const querySnapshot: firebase.firestore.QuerySnapshot = await notebooksRef
        .orderBy("order", "asc")
        .limit(1)
        .get();
      defaultNotebook = Notebook.from(querySnapshot.docs[0]);
    }

    return defaultNotebook;
  }

  public async create(userId: string, notebook: Notebook): Promise<Notebook> {
    const notebookRef: firebase.firestore.DocumentReference = await this.db
      .collection("users")
      .doc(userId)
      .collection("notebooks")
      .add(notebook.toMap());
    return Notebook.from(await notebookRef.get());
  }

  public async update(userId: string, notebook: Notebook): Promise<Notebook> {
    await this.db
      .collection("users")
      .doc(userId)
      .collection("notebooks")
      .doc(notebook.id)
      .set(notebook.toMap());
    return notebook;
  }

  public async delete(userId: string, notebook: Notebook): Promise<void> {
    await this.db
      .collection("users")
      .doc(userId)
      .collection("notebooks")
      .doc(notebook.id)
      .delete();
  }
}
