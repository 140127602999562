import "firebase/firestore";

export default class User {
  constructor(
    public readonly displayName: string,
    public readonly uid: string,
    public readonly lastSelectedNotebookId?: string
  ) {}

  public static fromSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
    return snapshot.exists ? new User(
      snapshot.data().displayName,
      snapshot.id,
      snapshot.data().lastSelectedNotebookId ?? null
    ) : null;
  }

  public toMap() {
    return {
      displayName: this.displayName,
      lastSelectedNotebookId: this.lastSelectedNotebookId,
    };
  }
}
