import React, { useContext } from "react";
import firebase from "../../firebase";
import "firebase/auth";
import "firebase/firestore";
import { AppContext } from "../../context/AppContext";
import { Button, Icon } from "semantic-ui-react";

const Login = () => {
  const appContext = useContext(AppContext);

  const handleGoogleClick = () => {
    appContext.setLoginLoading(true);
      firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .catch(console.log);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Button color="google plus" onClick={handleGoogleClick}>
        <Icon name="google" />
        Sign in with Google
      </Button>
    </div>
  );
};

export default Login;
