import React, { useContext, Fragment, useEffect, useReducer, useCallback, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Note from "./note";
import NoteModel from "../../model/Note";
import EditNoteModal from './edit-note-modal';
import { Confirm, Dimmer, Loader } from "semantic-ui-react";
import NotesService, { INotesService, NoteChangeEvent } from "../../service/notes-service";
import NotesUtil from "../../service/notes-util";
import "react-datepicker/dist/react-datepicker.css";
import { AddNoteButtons } from "./add-note-buttons";

interface DailyNotesState {
  notes: NoteModel[];
  noteDates: Date[];
  noteBeingEdited: NoteModel;
  noteBeingDeleted: NoteModel;
  noteExistsForToday: boolean;
}

type DailyNotesAction =
  {
    type: 'updateNotes',
    changes: NoteChangeEvent[]
  } | {
    type: 'reset'
  } | {
    type: 'setNoteBeingEdited',
    note: NoteModel
  } | {
    type: 'setNoteBeingDeleted',
    note: NoteModel
  };

const initialState: DailyNotesState = {
  notes: null,
  noteDates: [],
  noteBeingEdited: null,
  noteBeingDeleted: null,
  noteExistsForToday: true
};

function reducer(state: DailyNotesState, action: DailyNotesAction): DailyNotesState {
  if (action.type === 'updateNotes') {
    let updatedNotes: NoteModel[] = state.notes || [];
    for (const change of action.changes) {
      updatedNotes = NotesUtil.merge(change, updatedNotes);
    }
    return {
      ...state,
      notes: updatedNotes.sort((a: any, b: any) => a.date - b.date),
      noteExistsForToday: updatedNotes.filter(n => n.isToday()).length > 0,
      noteDates: updatedNotes.map(n => n.date)
    };
  } else if (action.type === 'reset') {
    return initialState;
  } else if (action.type === 'setNoteBeingEdited') {
    return {
      ...state,
      noteBeingEdited: action.note
    };
  } else if (action.type === 'setNoteBeingDeleted') {
    return {
      ...state,
      noteBeingDeleted: action.note
    };
  }

  return state;
}

export default function DailyNotes() {
  const { user, currentNotebook } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [notesService] = useState<INotesService>(new NotesService());

  const onBottomPaddingLoaded = useCallback(node => {
    if (!scrolled && node) {
      node.scrollIntoView({ behavior: 'smooth' });
      setScrolled(true);
    }
  }, [scrolled]);

  useEffect(() => {
    dispatch({ type: 'reset' });
    const unsubscribe: Function = notesService.listen(user.uid, currentNotebook.id, async (changes: NoteChangeEvent[]) => {
      dispatch({ type: 'updateNotes', changes });
    });
    return () => unsubscribe();
  }, [user, currentNotebook, notesService]);

  const deleteNote = async () => {
    await notesService.delete(user.uid, state.noteBeingDeleted);
    dispatch({ type: 'setNoteBeingDeleted', note: null })
  };

  if (!state.notes) {
    return <Dimmer inverted active> <Loader inverted content='Loading your notes...' /> </Dimmer>;
  }

  return (
    <Fragment>
      { state.notes?.map((n: NoteModel) => {
        return <Note note={n} key={n.id}
          onClick={() => dispatch({ type: 'setNoteBeingEdited', note: n })}
          delete={() => dispatch({ type: 'setNoteBeingDeleted', note: n })} />;
      })}

      <div style={{ textAlign: 'center', marginTop: '2em' }} >
        <AddNoteButtons
          disableTodayButton={state.noteExistsForToday}
          onNoteCreated={(note: NoteModel) => dispatch({ type: 'setNoteBeingEdited', note: note })}
          excludeDates={state.noteDates}
          notesService={notesService} />
      </div>

      <div id='bottom-padding' ref={onBottomPaddingLoaded} style={{ height: '15vh' }} />

      <EditNoteModal
        open={!!state.noteBeingEdited}
        onClose={() => dispatch({ type: 'setNoteBeingEdited', note: null })}
        note={state.noteBeingEdited}
        excludeDates={state.noteDates} />

      <Confirm
        open={!!state.noteBeingDeleted}
        cancelButton='Cancel'
        confirmButton="Yes, delete it"
        content="Really? It can't be undone."
        onCancel={() => dispatch({ type: 'setNoteBeingDeleted', note: null })}
        onConfirm={deleteNote}
        size='mini' />
    </Fragment>
  );
};