import * as React from 'react';
import ReactQuill from 'react-quill';
import { Card, Loader } from 'semantic-ui-react';
import Note from '../../model/Note';
import { INotesService } from '../../service/notes-service';
import { AppContext } from '../../context/AppContext';
import htmmlToText from 'html-to-text';
import './note.css';
import 'react-quill/dist/quill.snow.css';
import './quill-snow-custom.css';
import "react-datepicker/dist/react-datepicker.css";
import { Subject, Subscription } from 'rxjs';
import { tap, debounceTime, concatMap } from 'rxjs/operators';

export interface IEditableNoteProps {
  note: Note;
  excludeDates: Date[];
  notesService: INotesService;
}

export default function EditableNote(props: IEditableNoteProps) {
  const [note, setNote] = React.useState<Note>(props.note);
  const [textChange] = React.useState<Subject<string>>(new Subject<string>());
  const [notesService] = React.useState<INotesService>(props.notesService);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = React.useContext(AppContext);

  const deleteNoteIfNoTextCallback = React.useCallback(() => {
    const plainText: string = htmmlToText.fromString(note?.textHtml);
    if (!plainText) {
      notesService.delete(user.uid, note);
    }
  }, [note, user, notesService]);

  React.useEffect(() => {
    const sub: Subscription = textChange.pipe(
      tap((value) => {
        setNote(note => {
          // I reuse the note from prev state by just updating 
          // the textHtml property to prevent triggering this effect
          // as note is a dependency
          note.textHtml = value;
          return note;
        });
        setLoading(true);
      }),
      debounceTime(500),
      concatMap(async value => await notesService.update(user.uid, note)),
    ).subscribe(() => {
      setLoading(false);
    });

    return () => {
      // component cleanup
      sub.unsubscribe();
      deleteNoteIfNoTextCallback();
    }
  }, [note, notesService, textChange, user, deleteNoteIfNoTextCallback]);


  return (
    <Card centered className='note' style={{ width: '100%', minHeight: '20em', maxHeight: '60vh', overflow: 'auto' }} >
      <Card.Content>
        <Card.Header style={{ padding: '5px 0' }}>
          {note.getDateFormatted()}
          {loading && <Loader active inline inverted size='small' className='workaround' style={{ float: 'right' }} />}
        </Card.Header>
        <Card.Description>
          <ReactQuill
            value={note.textHtml}
            onChange={(newText: string) => textChange.next(newText)}
            theme={'snow'}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['clean']
              ],
            }} />
        </Card.Description>
      </Card.Content>
    </Card>
  );
};