import React, { useContext } from "react";
import './App.css';
import Header from './components/layout/header';
import 'semantic-ui-css/semantic.min.css'
import { AppContext } from "./context/AppContext";
import Login from './components/login/login'; import { LoginLoading } from "./components/login/login-loading";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import MainContent from "./components/layout/maincontent";
import DailyNotes from "./components/daily-notes/daily-notes";

function App() {
  const { loginLoading } = useContext(AppContext);
  return (
    <div className='app'>
      <Header />
      <MainContent >
        {loginLoading ? <LoginLoading /> :
          <Switch>
            <PrivateRoute exact path="/" component={DailyNotes} />
            <Route path="/login" component={Login} />
            <Redirect to="/" />
          </Switch>}
      </MainContent>
    </div>
  );
}

export default App;
