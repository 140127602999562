import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { AppContext } from '../../context/AppContext';
import Notebook from '../../model/Notebook';
import NotebooksService from '../../service/notebooks-service';

export interface RenameNotebookModalProps {
  open: boolean;
  close: any;
  notebook: Notebook;
}

export default function RenameNotebookModal(props: RenameNotebookModalProps) {
  const { user } = React.useContext(AppContext);
  const [notebookName, setNewNotebookName] = useState<string>(props.notebook?.name);
  const [newNotebookNameInputError, setNewNotebookNameInputError] = useState<any>(null);
  const [updateNotebookInProgress, setUpdateNotebookInProgress] = useState<boolean>(false);
  useEffect(() => {
    if (notebookName) {
      setNewNotebookNameInputError(null);
    }
  }, [notebookName]);

  useEffect(() => setNewNotebookName(props.notebook?.name), [props]);

  const saveClicked = async () => {
    if (!notebookName) {
      setNewNotebookNameInputError({
        content: 'Please enter a name',
        pointing: 'below'
      });
      return;
    }

    setUpdateNotebookInProgress(true);
    props.notebook.name = notebookName;
    await new NotebooksService().update(user.uid, props.notebook);
    setUpdateNotebookInProgress(false);
    props.close();
  };

  return (
    <Modal centered={false}
      size='small'
      open={props.open}
      onClose={props.close} >

      <Modal.Header>Rename notebook</Modal.Header>
      <Modal.Content>
        <Form loading={updateNotebookInProgress}>
          <Form.Field label='Name' control={Input} value={notebookName} placeholder='My notebook' error={newNotebookNameInputError} onChange={(evt: React.SyntheticEvent, data: any) => setNewNotebookName(data.value)} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={saveClicked} disable={updateNotebookInProgress}>
          Save
      </Button>
      </Modal.Actions>
    </Modal>
  );
}