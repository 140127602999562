import firebase from "firebase";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Notebook from "../model/Notebook";
import User from "../model/User";
import NotebooksService from "../service/notebooks-service";
import UserService from "../service/user-service";
import { AppContext } from "./AppContext";

export const AppContextProvider = (props: any) => {
  const [user, setUser] = useState<User>(props.user);
  const [loginLoading, setLoginLoading] = useState<boolean>(true); // loading by default when the page loads
  const [currentNotebook, setCurrentNotebook] = useState<Notebook>();
  const [firebaseUser, setFirebaseUser] = useState<firebase.User>();


  useEffect(() => {
    firebase
      .auth()
      .onAuthStateChanged(async (updatedFirebaseUser: firebase.User) => {
        setFirebaseUser(updatedFirebaseUser);
        if (updatedFirebaseUser) {
          await new UserService().createIfNotExists(
            updatedFirebaseUser.uid,
            updatedFirebaseUser.displayName
          );
        }
      });
  }, []);

  const history = useHistory();

  useEffect(() => {
    let unsubscribe: Function = null;
    if (firebaseUser) {
      unsubscribe = new UserService().listen(
        firebaseUser.uid,
        async (updatedUser?: User) => {
          if (user?.uid !== updatedUser?.uid) {
            let currentNotebook = null;
            if (updatedUser) {
              currentNotebook =
                await new NotebooksService().retrieveOrCreateDefaultNotebook(
                  updatedUser
                );
            }
            setCurrentNotebook(currentNotebook);
          }

          if (!_.isEqual(user, updatedUser)) {
            setUser(updatedUser);
          }

          if (user?.uid !== updatedUser?.uid) {
            history.push('/')
          }

          setLoginLoading(false);
        }
      );
    } else {
      setLoginLoading(false);
    }

    return () => unsubscribe && unsubscribe();
  }, [firebaseUser, history, user]);

  useEffect(() => {
    if (!firebaseUser) {
      setUser(null);
      setCurrentNotebook(null);
    }
  }, [firebaseUser]);

  const selectNotebook = (notebook: Notebook) => {
    new UserService().setLastSelectedNotebook(user.uid, notebook.id);
    setCurrentNotebook(notebook);
  };

  return (
    <AppContext.Provider
      value={{
        user: user,
        setUser: setUser,
        loginLoading: loginLoading,
        setLoginLoading: setLoginLoading,
        currentNotebook: currentNotebook,
        selectNotebook,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
