import React from 'react';
import Notebook from '../model/Notebook';
import User from '../model/User';
interface AppContext {
  user: User;
  setUser: Function;
  loginLoading: boolean;
  setLoginLoading: Function;
  currentNotebook: Notebook;
  selectNotebook: Function;
}

export const AppContext = React.createContext<AppContext>(null);

