import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { AppContext } from '../../context/AppContext';
import Notebook from '../../model/Notebook';
import NotebooksService from '../../service/notebooks-service';

export interface CreateNotebookModalProps {
  open: boolean;
  close: any;
  allNotebooks: Notebook[];
}

export function CreateNotebookModal(props: CreateNotebookModalProps) {
  const { user } = React.useContext(AppContext);
  const [newNotebookName, setNewNotebookName] = useState<string>();
  const [newNotebookNameInputError, setNewNotebookNameInputError] = useState<any>(null);
  const [createNotebookInProgress, setCreateNotebookInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (newNotebookName) {
      setNewNotebookNameInputError(null);
    }
  }, [newNotebookName]);

  const createClicked = async () => {
    if (!newNotebookName) {
      setNewNotebookNameInputError({
        content: 'Please enter a name',
        pointing: 'below'
      });
      return;
    }

    setCreateNotebookInProgress(true);
    const notebook: Notebook = new Notebook();
    notebook.name = newNotebookName;
    notebook.order = props.allNotebooks.sort(n => n.order - n.order)[props.allNotebooks.length - 1].order + 1;
    await new NotebooksService().create(user.uid, notebook);
    setCreateNotebookInProgress(false);
    props.close();
  }

  return (
    <Modal
      centered={false}
      size='small'
      open={props.open}
      onClose={props.close}
    >
      <Modal.Header>New notebook</Modal.Header>
      <Modal.Content>
        <Form loading={createNotebookInProgress}>
          <Form.Field
            control={Input}
            label='Name'
            placeholder='My new notebook'
            error={newNotebookNameInputError}
            onChange={(evt: React.SyntheticEvent, data: any) => setNewNotebookName(data.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={createClicked} disable={createNotebookInProgress} >
          Create
      </Button>
      </Modal.Actions>
    </Modal>
  );
}
