export default class Notebook {
  id: string;
  order: number;
  name: string;

  public static createDefault() {
    const notebook: Notebook = new Notebook();
    notebook.order = 1;
    notebook.name = 'Default';
    return notebook;
  }

  public static from(documentSnapshot: firebase.firestore.DocumentSnapshot): Notebook {
    const notebook: Notebook = new Notebook();
    notebook.id = documentSnapshot.id;
    notebook.name = documentSnapshot.data()['name'];
    notebook.order = documentSnapshot.data()['order'];
    return notebook;
  }

  public toMap(): any {
    return {
      name: this.name,
      order: this.order
    };
  }
}