import * as React from 'react';
import { useState } from 'react';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';
import { AppContext } from '../../context/AppContext';
import Notebook from '../../model/Notebook';
import NotebooksService from '../../service/notebooks-service';

export interface DeleteNotebookConfirmModalProps {
  open: boolean;
  close: any;
  notebook: Notebook;
  allNotebooks: Notebook[];
}

export default function DeleteNotebookConfirmModal(props: DeleteNotebookConfirmModalProps) {
  const { user, selectNotebook } = React.useContext(AppContext);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [confirmNameText, setConfirmNameText] = useState<string>();
  const [confirmNameInputError, setConfirmNameInputError] = useState<any>();

  const onConfirm = async () => {
    if (!props.notebook || confirmNameText !== props.notebook.name) {
      setConfirmNameInputError({
        content: 'Incorrect',
        pointing: 'below'
      });
      return;
    }

    setDeleteInProgress(true);
    await new NotebooksService().delete(user.uid, props.notebook);
    const newCurrent: Notebook = props.allNotebooks.find(n => n.id !== props.notebook.id);
    selectNotebook(newCurrent);
    setDeleteInProgress(false);
    props.close();
  };

  React.useEffect(() => {
    setConfirmNameInputError(null);
  }, [confirmNameText])

  return (
    <Modal
      centered={false}
      size='small'
      open={props.open}
      onClose={props.close} >
      <Modal.Header>Delete notebook</Modal.Header>
      <Modal.Content>
        <Message warning>
          <Message.Header>Warning - this cannot be undone!</Message.Header>
          <p>
            Enter the name of the notebook <b>"<code>{props.notebook?.name}</code>"</b> then click <b>Confirm</b> to delete the notebook.
            </p>
        </Message>
        <Form loading={deleteInProgress}>
          <Form.Field
            control={Input}
            placeholder={props.notebook?.name}
            onChange={(evt: React.SyntheticEvent, data: any) => setConfirmNameText(data.value)}
            error={confirmNameInputError} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onConfirm} disable={deleteInProgress}>
          Confirm
      </Button>
      </Modal.Actions>
    </Modal>
  );
}
