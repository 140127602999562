import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { AppContext } from '../../context/AppContext';
import Note from '../../model/Note';
import { INotesService } from '../../service/notes-service';
import DatePicker from "react-datepicker";

export interface IAddNoteButtonsProps {
  onNoteCreated: Function;
  excludeDates: Date[];
  disableTodayButton: boolean;
  notesService: INotesService;
}

export function AddNoteButtons(props: IAddNoteButtonsProps) {
  const { user, currentNotebook } = React.useContext(AppContext);
  const [todayButtonLoading, setTodayButtonLoading] = React.useState<boolean>(false);
  const [datePickerLoading, setDatePickerLoading] = React.useState<boolean>(false);
  const { notesService } = props;

  const addNewNote = async (date: Date) => {
    const newNote = await notesService.create(user.uid, new Note(date, currentNotebook.id));
    props.onNoteCreated(newNote);
  }

  const handleTodayButtonPressed = async () => {
    setTodayButtonLoading(true);
    await addNewNote(new Date());
    setTodayButtonLoading(false);
  }

  const handleDateSelected = async (date: Date) => {
    setDatePickerLoading(true);
    await addNewNote(date);
    setDatePickerLoading(false);
  }

  return (
    <div>
       <Button.Group size='big' primary>
          <Button
            onClick={handleTodayButtonPressed}
            disabled={props.disableTodayButton || todayButtonLoading || datePickerLoading}
            loading={todayButtonLoading} >
            <Icon name='add' /> Today
          </Button>
          <Button.Or />
          <DatePicker
            excludeDates={props.excludeDates}
            onChange={handleDateSelected}
            disabled={todayButtonLoading || datePickerLoading}
            customInput={<Button icon='calendar' style={{ width: '3em' }} loading={datePickerLoading} />} />
        </Button.Group>
    </div>
  );
}
